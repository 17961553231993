@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100&family=Playfair+Display&display=swap);
body {
  margin: 0;  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  background-color: #38ada9;
  color: white;
}

.collaps{
  max-height: 50vh !important;
  padding-top: 1em;
  margin-top: 1em;
}
body, html{
    font-family: 'Barlow';
    background: url(/static/media/FondoPrincipal.d29e2307.png);    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 100% !important;
}
.App {    
    overflow: hidden;
    justify-content: space-between;
    /* z-index: -1; */
    position:relative;
}


.notificacion {

	text-align: left;
/* 	padding: 10px 20px;
 */	
/*  margin-top: 15px; */
	/* margin-bottom: 2.5px; */
/*     position: absolute;
 */    z-index: 2;
    width: 100%; 
   /*  margin: 0 auto */
    /* z-index: 0;
    position: absolute;
 
 top: 0;
    left: 0;
    right: 0;
  
  */
    /* opacity: 0.1; */


}
.notificacion-info {
	background-color:  #daae2a ;
  color: white;
	border-left: 6px solid  #e3d294  ;
    font-weight: bold;
    padding: 20px;
    margin-bottom: 2.5px;
}
.app-logo{
    width: 100px;
    height: 70px;
    float: right
}
.letras{
  
    float: left
}
.colorLet{color: #0d479c ;   font-weight: bold;}
.colorLetraDorada{color: #DAAE2A ;   font-weight: bold;}


.App.blur{
    filter: blur(10px);
}

.App-content {
    height: 100%;
}

@media (min-width: 650px ) {
    .App {
        background-position: center center;
    }

    .App-content {
        align-items: center;
        justify-content: center;
    }
}

.text-center {
    text-align: center;
}

.tiendas:hover {
    cursor: pointer;    
    border-radius: 15px;    
    box-shadow: 0px 0px 28px 0px rgba(0,132,255,1);
}

/*-----Margenes-----*/
.m-10 { 
    margin-bottom: 10px !important;
}
.m-25 {
    margin: 25px !important;
}
.m-50 {
    margin: 50px !important;
}
.m-75 {
    margin: 75px !important;
}
.m-100 {
    margin: 100px !important;
}
.mt-25 {
    margin-top: 25px !important;
}
.mt-50 {
    margin-top: 50px !important;
}
.mt-75 {
    margin-top: 75px !important;
}
.mt-100 {
    margin-top: 100px !important;
}
/*-----Paddings-----*/
.p-25 {
    padding: 25px !important;
}
.p-50 {
    padding: 50px !important;
}
.p-75 {
    padding: 75px !important;
}
.p-100 {
    padding: 100px !important;
}
.pt-25 {
    padding-top: 25px !important;
}
.pt-50 {
    padding-top: 50px !important;
}
.pt-75 {
    padding-top: 75px !important;
}
.pt-100 {
    padding-top: 100px !important;
}

.no-flex{
    display: block !important;
}

.fontBold{
    font-weight: bold;
}

/*------Formularios------*/
.card {
    background: #fff;
    color: #000030;
    font-weight: bold;
    width: 100% !important;
    border-radius: 10px;
}

.card-action {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.btn-card button
{
    margin-top: 10px;
}

.align-vertically {
    display: flex;
    align-items: center; /* Alinea verticalmente el contenido */
    justify-content: center;
  }
  
  @media (max-width: 992px) { /* Establece las reglas de estilo para dispositivos con ancho máximo de 992px (tamaño de pantalla de tablet) */
    .row {
      flex-direction: column; /* Apila las columnas verticalmente */
    }

    .wrapper {
        margin-bottom: 15px;
    }

    #infoAsamblea {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
  }

  @media (max-width: 768px) { /* Establece las reglas de estilo para dispositivos con ancho máximo de 992px (tamaño de pantalla de tablet) */
    body, html{        
        height: auto !important;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    body, html{        
        height: 100% !important;
    }
  }
  

.rotate {
    transition: transform 0.4s;
    transform-style: preserve-3d;
    transform: rotateY(180deg);
}


.rotate .Toastify__toast-body {
    transform: rotateY(180deg);
    /* display: flex;
    justify-content: space-around; */
}
