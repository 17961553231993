
.rotate {
    transition: transform 0.4s;
    transform-style: preserve-3d;
    transform: rotateY(180deg);
}


.rotate .Toastify__toast-body {
    transform: rotateY(180deg);
    /* display: flex;
    justify-content: space-around; */
}